import React, { useState, useEffect } from "react";
import CustomIcon from "../icon";
import {
  black,
  elevationLight1,
  neutral300,
  neutral400,
  primary300,
  primary500,
  grey600,
  white,
} from "../../lib/rebrandColors/colors";
import { useLocation } from "react-router-dom";
import '../../App.css'; // Import your fonts
import { fetchOutletSavings } from '../../functions/savings';
import TextButton from "../textButton";
import { CircularProgress } from "@mui/material";
import { sumArrayIgnoreNegatives } from "../../functions/wattHours";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import Chip from "../chip";
import OutletModal from "../outlet-modal";
import DropDown from "../dropDown";
import IonIcon from "@reacticons/ionicons";

export default function OutletList({
  outlets,
  setOutlets,
  width,
  height,
  wattHours,
  savings = [],
  orgData,
  loading,
  prodTypes,
  modalEdit,
  setModalEdit,
}) {
  const location = useLocation();
  const [filter, setFilter] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState(outlets);
  const [currentOutlet, setOutlet] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState();
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );

  useEffect(() => {
    setTimezone(localStorage.getItem('timezone'));
  }, [orgData]);

  const handleRowClick = (outlet, i) => {
    if (isModalOpen == false) {
      setIndex(i);
      setOutlet(outlet);
      setIsModalOpen(true);
    }
  };

  const filterOutlets = (outlets, filterType) => {
    // sort by name
    return outlets.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      const wifiStatusA = a.wifiStatus;
      const wifiStatusB = b.wifiStatus;
      const usageA = a.dailyUsage;
      const usageB = b.dailyUsage;
      const statusA = a.status;
      const statusB = b.status;
      const savingsA = a.costSavings;
      const savingsB = b.costSavings;
      if (filterType === "name") {
        if (filter) {
          if (nameA < nameB) {
            return -1;
          };
          if (nameA > nameB) {
            return 1;
          };
        } else {
          if (nameA < nameB) {
            return 1;
          };
          if (nameA > nameB) {
            return -1;
          };
        }
      } else if (filterType === "wifiStatus") {
        if (filter) {
          if (wifiStatusA < wifiStatusB) {
            return -1;
          };
          if (wifiStatusA > wifiStatusB) {
            return 1;
          };
        } else {
          if (wifiStatusA < wifiStatusB) {
            return 1;
          };
          if (wifiStatusA > wifiStatusB) {
            return -1;
          };
        };
      } else if (filterType === "usage") {
        if (filter) {
          if (usageA < usageB) {
            return 1;
          };
          if (usageA > usageB) {
            return -1;
          };
        } else {
          if (usageA < usageB) {
            return -1;
          };
          if (usageA > usageB) {
            return 1;
          };
        };
      } else if (filterType === "status") {
        if (filter) {
          if (statusA < statusB) {
            return -1;
          };
          if (statusA > statusB) {
            return 1;
          };
        } else {
          if (statusA < statusB) {
            return 1;
          };
          if (statusA > statusB) {
            return -1;
          };
        };
      } else if (filterType === "savings") {
        if (filter) {
          if (savingsA < savingsB) {
            return 1;
          };
          if (savingsA > savingsB) {
            return -1;
          };
        } else {
          if (savingsA < savingsB) {
            return -1;
          };
          if (savingsA > savingsB) {
            return 1;
          };
        };
      };
      // names must be equal
      return 0;
    });
  };

  // Function to update objects in array2 based on matching ids in array1
  const updateArray2 = (array1, array2) => {
    const updatedArray2 = array2.map(item2 => {
      // Find the matching object from array1

      const match = array1.find(item1 => {
        return item1.devices.includes(item2.device);
      });

      if (match) {
        // Add fields from the matched object to item2
        return { ...item2, orgName: match.name, };
      };

      return item2; // Return item2 unchanged if no match
    });

    // Update state with the modified array2
    return updatedArray2;
  };

  useEffect(() => {
    const adjustOutlets = async () => {
      setFilterLoading(true)
      //Add dailyUsage to the outlets array of objects
      
      let today = new Date().toLocaleDateString("en-US", {
        timeZone: timezone || 'UTC',
      });
      // Split the date string into components
      const [month, day, year] = today.split("/");
      // Construct the date in YYYY-MM-DD format
      today = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      // Step 1: Filter the array for objects with today's date
      const filteredWattHours = wattHours.filter((obj) => obj.date === today);
      // Step 2: Update each object in the array to include a 'value' key with the sum of 'values'
      const sumWattHours = filteredWattHours.map((obj) => ({
        ...obj,
        dailyUsage: obj.hourlyWattHour.reduce(
          (acc, val) => (val > 0 ? acc + val : acc),
          0
        ),
      }));
      // Step 3: Convert sumWattHours to a Map for fast lookup
      const idToDailyUsageMap = new Map(
        sumWattHours.map((obj) => [obj.outletID, obj.dailyUsage])
      );

      const sumValuesById = (array) => {
        let result = []
        if (array.length > 0) {
          result = array.reduce((acc, { outletID, costSavings }) => {
            if (acc[outletID]) {
              acc[outletID].costSavings += costSavings; // Sum values for the same id
            } else {
              acc[outletID] = { outletID, costSavings }; // Initialize with first occurrence
            };
            return acc;
          }, {});
        };

        return Object.values(result); // Convert the object back to an array
      };

      const sumSavings = sumValuesById(savings);
      const idToSavingsMap = new Map(
        sumSavings.map((obj) => [obj.outletID, obj.costSavings])
      );

      if (outlets) {
        if (outlets.length > 0) {
          // Step 4: Assign daily usage from sumWattHours to outlets based on id
          for (const obj of outlets) {
            //outlets.forEach((obj) => {
            if (idToDailyUsageMap.has(obj.id)) {
              obj.dailyUsage = idToDailyUsageMap.get(obj.id) / 1000;
            };
            if (!obj.dailyUsage) {
              obj.dailyUsage = 0;
            };
            if (idToSavingsMap.has(obj.id)) {
              obj.costSavings = idToSavingsMap.get(obj.id);
            };
            if (!obj.costSavings) {
              obj.costSavings = 0;
            };
          };
          //add orgNames to the outlets
          outlets = updateArray2(orgData, outlets);

          //filter the outlets
          setFilteredOutlets(filterOutlets(outlets, "savings"));
        };
      };
      setFilterLoading(false);
    };
    adjustOutlets();
  }, [loading, orgData, timezone]);

  return (
    <div style={{
      margin: '.5%',
      minWidth: '500px',
      borderRadius: '24px',
      minHeight: '824px',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '40px',
        marginTop: '40px',
        justifyContent: 'space-between',
      }}>
        {location.pathname === '/' &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <CustomIcon
              name="plug"
              color={black}
              size={30}
            />
            <div
              className="bodyLarge"
              style={{
                marginLeft: '8px',
              }}
            >
              Appliances
            </div>
          </div>
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: height, //? height : `${listHeight-100}px`,
        overflowY: 'scroll',
        alignItems: 'center',
        width: '100%'
      }}>
        {filterLoading ?
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px'
          }}>
            <CircularProgress />
          </div> :
          filteredOutlets.length > 0 ?
            filteredOutlets.map((outlet, i) => {
              return (
                // <OutletContainer outlet={outlet} width={width} prodTypes={prodTypes}/>
                <div
                  style={{
                    display: 'flex',
                    width: width ? width : '480px',
                    minHeight: '88px',
                    backgroundColor: '#EEEEEE',
                    borderRadius: '16px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '5px',
                    marginTop: '16px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleRowClick(outlet, i)}
                  key={outlet.id}
                >
                  <div style={{
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        className="labelMedium"
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: grey600
                        }}
                      >
                        {outlet.name}
                      </div>
                      <div className="labelMedium"
                        style={{
                          fontSize: '12px',
                          color: '#666666',
                        }}
                      >
                        {outlet.orgName}
                      </div>
                      <div className="labelMedium"
                        style={{
                          fontSize: '12px',
                          color: '#666666',
                        }}
                      >
                        Today: {outlet.dailyUsage.toFixed(2)} kWh
                      </div>
                      <div className="labelMedium"
                        style={{
                          color: outlet.status == 'Off' ? primary500 : 'green',
                          fontSize: '12px',
                        }}
                      >
                        {outlet.status}
                      </div>
                    </div>
                    {location.pathname === '/appliances' &&
                      <div>
                        <Chip label={outlet.productType == "" ? "N/A" : outlet.productType} alert={false} iconName='plug' />
                      </div>
                    }
                    {location.pathname === '/appliances' && (
                      outlet.wifiStatus === "Connected" ?
                        <div>
                          <Chip label={outlet.wifiStatus} alert={false} icon={<WifiIcon style={{ color: '#009C49' }} />} />
                        </div>
                        :
                        outlet.wifiStatus === "Disconnected" ?
                          <Chip label={outlet.wifiStatus} alert={true} icon={<WifiOffIcon style={{ color: '#FF6D2A' }} />} />
                          :
                          <Chip label={outlet.wifiStatus} alert={true} icon={<WifiLockIcon style={{ marginLeft: "3%", color: "orange" }} />} />
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div className="buttonSmall" style={{ fontSize: '10px', lineHeight: '10px', textAlign: 'center' }}>Estimated<br />savings:</div>
                      <div style={{
                        width: '63px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '24px',
                        backgroundColor: '#FFFFFF',
                        marginTop: '5px'
                      }}>
                        <div className="buttonSmall"
                          style={{
                            color: '#E50914',
                            fontSize: '12px',
                            fontWeight: '700'
                          }}
                        >
                          ${outlet.costSavings.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isModalOpen &&
                    <OutletModal
                      key={i}
                      id={i}
                      isOpen={index === i}
                      setIsModalOpen={setIsModalOpen}
                      outlet={currentOutlet}
                      setOutlet={setOutlet}
                      oldName={outlet.name}
                      dailyUsage={outlet.dailyUsage}
                      //rate={rate}
                      //selectOnPress={selectOnPress}
                      //submitEditName={submitEditName}
                      //width={width}
                      height={height}
                      outlets={outlets}
                      setOutlets={setOutlets}
                      //prodType={prodType}
                      //setProdType={setProdType}
                      prodTypes={prodTypes}
                      modalEdit={modalEdit}
                      setModalEdit={setModalEdit}
                    />
                  }
                </div>
              )
            }) :
            <div
              className="headlineMedium"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '50px',
                color: '#999999'
              }}
            >
              No appliances available
            </div>
        }
      </div>
    </div>
  );
};