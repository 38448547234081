import React, { useState, useEffect } from "react";
import { Box, Typography, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OnBar from "../onBar";
import { primary500 } from "../../lib/rebrandColors/colors";

export default function WeeklyScheduleView({ schedules, org, outlets, orgData }) {
  // const [filteredSchedules, setFilteredSchedules] = useState([]);
  // useEffect(() => {
  //   console.log('hmmm', org);

  //   if (org === 'All') {
  //     setFilteredSchedules(schedules);
  //   } else {
  //     setFilteredSchedules(
  //       schedules.filter((schedule) => {
  //         const matchingOrg = orgData.find((orgObj) => orgObj.name === org);

  //         return matchingOrg && schedule.orgID === matchingOrg.uid;
  //       })
  //     );
  //   };
  // }, [org])
  const hours = Array.from({ length: 24 }, (_, i) => i);

  return (
    <Box>
      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => {
        const daySchedules = schedules.filter((schedule) =>
          schedule.repeat.includes(day)
        );
        if (daySchedules.length === 0) return null;

        return (
          <DaySchedule
            key={day}
            day={day}
            daySchedules={daySchedules}
            hours={hours}
            outlets={outlets}
            org={org}
            orgData={orgData}
          />
        );
      })}
    </Box>
  );
};

function DaySchedule({ day, daySchedules, hours, outlets, org, orgData }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const today = new Date().toLocaleString("en-US", { weekday: "short" });
    if (day === today) {
      setOpen(true);
    };
  }, [day]);

  const formatHour = (hour) => {
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour} ${period}`;
  };

  const uniqueHours = [
    ...new Set(
      daySchedules.flatMap((schedule) => [schedule.onHour, schedule.offHour || 24])
    ),
  ].sort((a, b) => a - b);

  const selectedOutlets = daySchedules.reduce((acc, schedule) => {
    // For each schedule, filter the outlets based on outletsSelected
    const matchingOutlets = outlets.filter(outlet =>
      schedule.outletsSelected.includes(outlet.id)
    );
    // Add the matching outlets to the accumulator
    return [...acc, ...matchingOutlets];
  }, []);

  return (
    <Box sx={{ mb: 1 }}>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontFamily: "IBM Plex Mono",
            fontSize: "14px",
            color: primary500,
          }}
        >
          {day}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            height: "1px",
            backgroundColor: primary500,
            mx: 1,
            alignSelf: "center",
          }}
        />
        <IconButton>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={open}>
        <Box sx={{ position: "relative", mb: 2 }}>
          <Box sx={{ display: "flex", position: "absolute", top: "-16px", left: "150px" }}>
            {uniqueHours.map((hour) => (
              <Box
                key={hour}
                sx={{
                  position: "absolute",
                  left: `${hour * 40}px`,
                  width: "40px",
                  textAlign: "center",
                  color: primary500,
                  fontSize: "12px",
                  fontWeight: "bold",
                  transform: "translateY(-50%)",
                }}
              >
                {formatHour(hour)}
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              mt: 3.5,
              maxHeight: "250px",
              overflowY: "auto",
              scrollbarWidth: "none"
            }}
          >
            {selectedOutlets.map((outlet) => {
              return (
                <OutletRow
                  key={outlet.id}
                  outlet={outlet}
                  schedules={daySchedules}
                  hours={hours}
                  uniqueHours={uniqueHours}
                />
              );
            })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

function OutletRow({ outlet, schedules, hours, uniqueHours }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography
        sx={{
          width: "150px",
          fontSize: "0.8rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {outlet.name}
      </Typography>
      <Box sx={{ display: "flex", position: "relative", width: "960px" }}>
        {hours.map((hour) => (
          <Box
            key={hour}
            sx={{
              width: "40px",
              height: "30px",
              borderRight: uniqueHours.includes(hour) ? "0px dotted #ccc" : "none",
              position: "relative",
              top: "-10px",
            }}
          />
        ))}

        {schedules
          .filter((schedule) => schedule.outletsSelected.includes(outlet.id))
          .map((schedule) => (
            <OnBar
              key={schedule.id}
              width={`${schedule.offHour === 0
                ? (24 - schedule.onHour) * 40
                : (schedule.offHour - schedule.onHour) * 40
                }px`}
              offset={`${schedule.onHour * 40}px`}
            />
          ))}
      </Box>
    </Box>
  );
};
