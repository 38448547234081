import React, { useState, useEffect } from "react";
import CustomIcon from "../icon";
import {
    black,
    elevationLight1,
    neutral300,
    neutral400,
    primary500,
    grey600,
    white,
} from "../../lib/rebrandColors/colors";
import { fetchOutlets } from "../../functions/outletList";
import { useLocation } from "react-router-dom";
import '../../App.css'; // Import your fonts
import TextButton from "../textButton";
import { CircularProgress } from "@mui/material";
import Dropdown from "../dropDown";
import SpecialEventForm from "../specialEventForm";
import { addDoc, collection, doc, updateDoc, arrayUnion } from "firebase/firestore";
import db from "../../firebase";

export default function DailyScheduleView({
    day,
    schedules,
    width,
    height,
    orgID,
    selectedOrgID,
    isOverviewAccount,
    timezone,
    child_orgs,
    orgData,
    orgName,
    loading,
    outlets,
}) {
    const location = useLocation();
    const halfWidth = .45 * width;
    const getTodayDay = () => {
        const date = new Date();
        return date.toLocaleDateString("en-US", { weekday: "short", timeZone: timezone });
    };

    const [filteredSchedules, setFilteredSchedules] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    // const [outlets, setOutlets] = useState(
    //     JSON.parse(localStorage.getItem("outlets"))
    // );
    const [outletsLoading, setOutletsLoading] = useState(true);
    const [prodTypes, setProdTypes] = useState([]);

    const [statusDropdownValue, setStatusDropdownValue] = useState('All');
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const statusDropdownClick = (item) => {
        // console.log('item', item);
        setStatusDropdownValue(item);
    };
    const [statusDropdownArr, setStatusDropdownArr] = useState(['All', 'On', 'Off']);
    const [updatedTimeList, setUpdatedTimeList] = useState([]);
    const [productTypesArr, setProductTypesArr] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Submit handler for special event form
    const handleSpecialEventSubmit = async (eventData) => {
        // gets range of events
        const getDateRange = (start, end) => {
            const dates = [];
            let currentDate = new Date(start);
            const endDate = new Date(end);

            while (currentDate <= endDate) {
                dates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1); // Move to next day
            };
            return dates;
        };
        const dateRange = getDateRange(eventData.startDate, eventData.endDate);
        // finds orgs for events
        const matchingOrgs = orgData
            .map(org => {
                const matchedOutlets = eventData.outlets.filter(outlet => {
                    const trimmedOutlet = outlet.slice(0, -2); // Remove the last two characters
                    return org.devices.includes(trimmedOutlet)
                });
                return matchedOutlets.length > 0 ? { ...org, matchedOutlets } : null;
            }).filter(org => org !== null);
        // creates event for each day, and each org
        try {
            for (const date of dateRange) {
                const eventDay = date.toLocaleDateString("en-US", { weekday: "short" });

                for (const org of matchingOrgs) {

                    const dailyEventData = {
                        title: eventData.name,
                        date: date.toISOString().split("T")[0],
                        organizationID: org.uid,
                        outlets: org.matchedOutlets, // Only outlets that match this org
                        action: eventData.action,
                    };

                    await addDoc(collection(db, "events"), dailyEventData);
                    // updates events array in schedule
                    for (const schedule of schedules) {
                        if (
                            schedule.repeat.includes(eventDay) &&
                            org.matchedOutlets.some(outlet => schedule.outletsSelected.includes(outlet))
                        ) {
                            const scheduleDocRef = doc(db, "schedulesTest", schedule.id);

                            await updateDoc(scheduleDocRef, {
                                events: arrayUnion({
                                    eventDate: dailyEventData.date,
                                    outlets: org.matchedOutlets,
                                    action: dailyEventData.action,
                                }),
                            });
                        };
                    };
                };
            };
            console.log("Events created successfully!");
        } catch (error) {
            console.log('doc error', error);
        };
    };

    // Filter schedules whenever schedules or selectedDay changes
    useEffect(() => {
        let useDay = day;
        if (day === null) {
            useDay = new Date();
        };
        
        const convertedDay = useDay.toLocaleDateString("en-US", { weekday: "short", timeZone: timezone });

        const newFilteredSchedules = schedules.filter(
            (schedule) =>
                schedule.repeat.includes(convertedDay)
                && (schedule.status !== "Rejected" || schedule.status !== 'New')
        );

        setFilteredSchedules(newFilteredSchedules);
    }, [schedules, day]);

    const formatHour = (hour, minute) => {
        const period = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
        const formattedMinute = minute ? minute : '00';
        return `${formattedHour}:${formattedMinute} ${period}`;
    };

    // sets product type arr
    useEffect(() => {
        // structures schedules, splitting them to ons and offs
        const timeList = filteredSchedules.flatMap((schedule) => [
            {
                hour: schedule.onHour,
                minute: schedule.onMinute,
                time: formatHour(schedule.onHour, schedule.onMinute),
                direction: "On",
                id: schedule.id,
                name: schedule.missionId,
                ...schedule
            },
            {
                hour: schedule.offHour,
                minute: schedule.offMinute,
                time: formatHour(schedule.offHour, schedule.offMinute),
                direction: "Off",
                id: schedule.id,
                name: schedule.missionId,
                ...schedule
            },
        ]);
        // sorts schedules, including direction
        const filteredTimeList = timeList.filter((schedule) => {
            if (statusDropdownValue === 'All') return true;
            return schedule.direction === statusDropdownValue;
        }).sort((a, b) => a.hour - b.hour || a.minute - b.minute);

        const newProductTypesArr = filteredTimeList.map((schedule) => {
            const productTypes = schedule.outletsSelected
                .map(outlet => outlets.find(obj => obj.id === outlet)?.productType)
                .filter(Boolean);
            return [...new Set(productTypes)];
        });

        setProductTypesArr(newProductTypesArr);

        const updatedList = filteredTimeList.map(schedule => {
            if (orgData?.length > 0) {
                const org = orgData.find(orgItem => orgItem.uid === schedule.orgID);
                return { ...schedule, orgName: org ? org.name : '' };
            } else {
                return { ...schedule, orgName: orgData.name };
            };
        });

        setUpdatedTimeList(updatedList);
    }, [filteredSchedules, statusDropdownValue]);


    const listHeight = updatedTimeList.length > 0 ? updatedTimeList.length * 150 : 150;

    return (
        <div style={{
            // backgroundColor: 'red',
            width: '100%',
            // margin: '.5%',
            minWidth: '500px',
            borderRadius: '24px',
            // minHeight: '824px',
            // height:"792px"
            // textAlign: 'center',
            // overflowY: 'scroll'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '40px',
                marginTop: location.pathname === '/schedules' ? '0px' : '40px',
                justifyContent: 'space-between',
            }}>
                {location.pathname === '/' &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <CustomIcon
                            name="calendar"
                            color={black}
                            size={30}
                        />
                        <div
                            className="bodyLarge"
                            style={{
                                marginLeft: '8px',
                            }}
                        >
                            Schedule
                        </div>
                    </div>
                }
                {location.pathname === '/' && updatedTimeList.length !== 0 &&
                    <div style={{ marginRight: '40px' }}>
                        {/* need add event funtion */}
                        <TextButton
                            label="Add Special Event"
                            // disabled={true}
                            underlined={true}
                            fontSize={14}
                            onPress={openModal}
                        />
                    </div>
                }
                {location.pathname === '/schedules' && updatedTimeList.length !== 0 &&
                    <div style={{ marginRight: '40px', marginTop: '-8px' }}>
                        <Dropdown
                            list={statusDropdownArr}
                            value={statusDropdownValue}
                            onSelect={statusDropdownClick}
                            setIsDropdownOpen={setStatusDropdownOpen}
                            isDropdownOpen={statusDropdownOpen}
                            label={'Status'}
                            size={75}
                            background="light"
                        />
                    </div>
                }
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                scrollbarWidth: "none",
                //justifyContent: 'space-evenly',
                height: height ? height : `${listHeight - 100}px`,
                overflowY: 'scroll',
            }}>
                {loading ?
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px'
                    }}>
                        <CircularProgress />
                    </div>
                    :
                    updatedTimeList.length === 0 ?
                        <div
                            className="headlineMedium"
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                marginTop: '50px',
                                color: '#999999'
                            }}
                        >
                            No schedules today
                        </div>
                        :
                        updatedTimeList.map((schedule, i) => {
                            return (
                                <div
                                    key={`${schedule.id}, ${schedule.time}`}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '23px',
                                        marginBottom: '5px'
                                    }}
                                >
                                    <div
                                        className="buttonMedium"
                                        style={{
                                            marginRight: '2%',
                                            marginLeft: '1%',
                                            height: '60px',
                                            width: '60px',
                                            minWidth: '60px',
                                            borderRadius: 50,
                                            backgroundColor: white,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: primary500,
                                            fontSize: '10px',
                                            boxShadow: elevationLight1,
                                        }}
                                    >
                                        {schedule.time}
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        width: '90%',
                                        height: '88px',
                                        backgroundColor: white,
                                        borderRadius: '16px',
                                        border: `1px solid ${neutral300}`,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: elevationLight1,
                                        flexDirection: 'column',
                                        marginRight: '5px',
                                    }}>
                                        {/* Schedule Card */}
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            alignItems: 'center',
                                            marginLeft: '10px'
                                        }}>
                                            <div style={{
                                                height: '48px',
                                                width: '48px',
                                                borderRadius: '24px',
                                                backgroundColor: '#F1F1F1',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <CustomIcon
                                                    name="building-1"
                                                    color={black}
                                                    size={24}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                marginLeft: '10px'
                                            }}>
                                                <div
                                                    className="labelMedium"
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '700',
                                                        color: grey600
                                                    }}
                                                >
                                                    {schedule.name !== '' ?
                                                        schedule.name
                                                        : schedule.orgName
                                                    }
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'start',
                                                    width: '100%',
                                                    marginTop: '3px'
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        width: '97%',
                                                        overflow: 'hidden',
                                                    }}>
                                                        {productTypesArr[i].map((prodType, j) => {
                                                            return (
                                                                <div
                                                                    className="labelLarge"
                                                                    key={`${prodTypes}${j}`}
                                                                    style={{
                                                                        fontSize: productTypesArr[i].length > 2 ? '10px' : '12px',
                                                                        marginRight: '2%',
                                                                        color: neutral400,
                                                                        lineHeight: '10px',
                                                                    }}
                                                                >
                                                                    {prodType}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div
                                                        className="labelSmall"
                                                        style={{
                                                            color: neutral400,
                                                            marginTop: '3px'
                                                        }}
                                                    >
                                                        {schedule.direction === 'On' ? 'Outlets On' : 'Outlets Off'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {location.pathname === '/schedules' &&
                                            <div style={{ alignSelf: 'end', }}>
                                                {/* open schedule modal */}
                                                <TextButton
                                                    label="View Details"
                                                    disabled={true}
                                                    underlined={true}
                                                    fontSize={12}
                                                />
                                            </div>
                                        }
                                        <SpecialEventForm
                                            open={isModalOpen}
                                            handleClose={closeModal}
                                            handleSubmit={handleSpecialEventSubmit}
                                            isOverviewAccount={isOverviewAccount}
                                            child_org_data={orgData}
                                            outlets={outlets}
                                        />
                                    </div>
                                </div>
                            );
                        })
                }
            </div>
        </div>
    );
};