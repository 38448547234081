import React from 'react';
import { Box, Typography } from '@mui/material';
import { primary500, white } from '../../lib/rebrandColors/colors';

export default function OnBar({ width = '441px', offset = '0px' }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: width,
        padding: '2px 0', // Reduce padding for smaller appearance
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '40px',
        border: `1px solid ${primary500}`,
        background: `linear-gradient(0deg, rgba(229, 9, 20, 0.10) 0%, rgba(229, 9, 20, 0.10) 100%), ${white}`,
        transition: 'background 0.3s ease, color 0.3s ease',
        position: 'absolute',
        left: offset,
        '&:hover': {
          background: `linear-gradient(0deg, ${primary500} 0%, ${primary500} 100%), ${white}`,
          '& .on-text': {
            color: white,
          },
        },
      }}
    >
      <Typography
        variant="body2"
        className="on-text"
        sx={{
          color: primary500,
          fontWeight: 'bold',
          fontSize: '0.75rem', // Smaller font size
          textTransform: 'uppercase',
        }}
      >
        On
      </Typography>
    </Box>
  );
}
