import React, { useState } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { neutral400, primary500 } from "../../lib/rebrandColors/colors";
import MonthlyCalendar from "../monthlyCalendar";
import TimePicker from "../timePicker";
import EditIcon from "@mui/icons-material/Edit";
import DailyScheduleView from "../dailyScheduleView";
import SpecialEventForm from "../specialEventForm";
import { addDoc, collection, updateDoc, doc, arrayUnion } from "firebase/firestore";
import db from "../../firebase";
import EditForm from "../editForm";
import WeeklyScheduleView from "../weeklyScheduleView";

export default function CalendarContainer({
  events,
  schedules,
  orgID,
  selectedOrgID,
  isOverviewAccount,
  timezone,
  orgName,
  child_orgs,
  orgData,
  loading,
  windowDimensions,
  outlets,
  refresh,
  setRefresh,
  subOrgDropdownValue,
}) {
  // NOTE: put back to week
  const [view, setView] = useState("week"); // Default to month view
  const [selectedDate, setSelectedDate] = useState(new Date()); // Store the date selected by TimePicker
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Update date based on TimePicker selection
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Handle view change
  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  // Modal open/close handlers
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);


  // Submit handler for special event form
  const handleSpecialEventSubmit = async (eventData) => {
    // gets range of events
    const getDateRange = (start, end) => {
      const dates = [];
      let currentDate = new Date(start);
      const endDate = new Date(end);

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1); // Move to next day
      };
      return dates;
    };
    const dateRange = getDateRange(eventData.startDate, eventData.endDate);
    // finds orgs for events
    const matchingOrgs = orgData
      .map(org => {
        const matchedOutlets = eventData.outlets.filter(outlet => {
          const trimmedOutlet = outlet.slice(0, -2); // Remove the last two characters
          return org.devices.includes(trimmedOutlet)
        });
        return matchedOutlets.length > 0 ? { ...org, matchedOutlets } : null;
      }).filter(org => org !== null);
    // creates event for each day, and each org

    try {
      for (const date of dateRange) {
        const eventDay = date.toLocaleDateString("en-US", { weekday: "short" });

        for (const org of matchingOrgs) {

          const dailyEventData = {
            title: eventData.name,
            date: date.toISOString().split("T")[0],
            organizationID: org.uid,
            outlets: org.matchedOutlets, // Only outlets that match this org
            action: eventData.action,
          };

          await addDoc(collection(db, "events"), dailyEventData);
          // updates events array in schedule
          for (const schedule of schedules) {
            if (
              schedule.repeat.includes(eventDay) &&
              org.matchedOutlets.some(outlet => schedule.outletsSelected.includes(outlet))
            ) {
              // NOTE: Update to schedules collection
              const scheduleDocRef = doc(db, "schedules", schedule.id);

              await updateDoc(scheduleDocRef, {
                events: arrayUnion({
                  eventDate: dailyEventData.date,
                  outlets: org.matchedOutlets,
                  action: dailyEventData.action,
                }),
              });
            };
          };
        };
      };
      console.log("Events created successfully!");
      setRefresh(!refresh);
    } catch (error) {
      console.log('doc error', error);
    };
  };

  const handleEditSubmit = async (editData) => {
    console.log('edit schedule result', editData);
    try {
      // NOTE: Update to schedules collection
      const scheduleRef = doc(db, 'schedules', editData.id);
      await updateDoc(scheduleRef, {
        missionId: editData.missionId,
        offHour: editData.offHour,
        offMinute: editData.offMinute,
        onHour: editData.onHour,
        onMinute: editData.onMinute,
        outletsSelected: editData.outletsSelected,
        repeat: editData.repeat
      });
      console.log('doc updated successfully');
      setRefresh(!refresh);
    } catch (error) {
      console.log('error updating', error);
    };
  };

  // console.log("Child orgs",child_orgs);
  // console.log("Child orgs data", orgData);


  return (
    <Box
      sx={{
        width: "1136px", // Fixed width
        height: "792px", // Fixed height
        padding: "24px", // Padding inside the container for spacing
        backgroundColor: "#F9F9F9",
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden", // Prevent overflow outside container
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%", // Stretch header to full width of the container
        }}
      >
        {/* Tabs for View Selection */}
        <Tabs
          value={view}
          onChange={handleViewChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: primary500, // Red underline for the selected tab
            },
            "& .MuiTab-root": {
              textTransform: "none",
              minWidth: "auto",
              padding: "0 16px",
              color: neutral400, // Default color for unselected tabs
              fontFamily: "IBM Plex Mono",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            },
            "& .MuiTab-root.Mui-selected": {
              color: primary500, // Red color for the selected tab text
            },
          }}
        >
          <Tab label="Month" value="month" />
          <Tab label="Week" value="week" />
          <Tab label="Day" value="day" />
        </Tabs>

        {/* Conditional Button */}
        {view === "week" ? (
          <Button
            variant="outlined"
            onClick={openEditModal}
            sx={{
              borderColor: "#E50914",
              color: "#E50914",
              fontWeight: "bold",
              textTransform: "none",
              padding: "8px 16px",
              borderRadius: "24px",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                borderColor: "#E50914",
                backgroundColor: "transparent",
              },
            }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={openModal} // Opens modal on click
            sx={{
              borderColor: "#E50914",
              color: "#E50914",
              fontWeight: "bold",
              textTransform: "none",
              padding: "8px 16px",
              borderRadius: "24px",
              "&:hover": {
                borderColor: "#E50914",
                backgroundColor: "transparent",
              },
            }}
          >
            + Add special event
          </Button>
        )}
      </Box>

      {/* Time Picker */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <TimePicker
          timeSpan={view.charAt(0).toUpperCase() + view.slice(1)}
          setTime={handleDateChange}
        />
      </Box>

      {/* Calendar Content */}
      {view === "month" && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            padding: "16px", // Space around the calendar inside the container
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            scrollbarWidth: "none",
            justifyContent: "center",
            // overflowX: "auto", // Horizontal scroll if needed for smaller screens
          }}
        >
          <MonthlyCalendar date={selectedDate} events={events} />
        </Box>
      )}
      {view === "week" && (
        schedules?.length === 0 ?
          <div
            className="headlineMedium"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '50px',
              color: '#999999'
            }}
          >
            No schedules today
          </div>
          :
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              justifyContent: "center",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <WeeklyScheduleView
              schedules={schedules}
              org={subOrgDropdownValue ? subOrgDropdownValue : 'All'}
              outlets={outlets}
              orgData={orgData}
            />
          </Box>
      )}
      {view === "day" && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <DailyScheduleView
            width={windowDimensions.width}
            height={'615px'}
            schedules={schedules}
            day={selectedDate}
            orgID={orgID}
            selectedOrgID={selectedOrgID}
            isOverviewAccount={isOverviewAccount}
            timezone={timezone}
            orgName={orgName}
            child_orgs={child_orgs}
            orgData={orgData}
            loading={loading}
            outlets={outlets}
          />
        </Box>
      )}

      {/* Special Event Form Modal */}
      <SpecialEventForm
        open={isModalOpen}
        handleClose={closeModal}
        handleSubmit={handleSpecialEventSubmit}
        isOverviewAccount={isOverviewAccount}
        child_org_data={orgData}
        outlets={outlets}
      />
      <EditForm
        open={isEditModalOpen}
        schedules={schedules}
        handleClose={closeEditModal}
        handleSubmit={handleEditSubmit}
        isOverviewAccount={isOverviewAccount}
        child_org_data={orgData}
        outlets={outlets}
      />
    </Box>
  );
};
