import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Alert } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { fetchUsersFromOrganization } from "../../functions/users";
import CustomButton from "../../components/customButton";
import CustomIcon from "../../components/icon";
import IconButton from "../../components/iconButton";
import TextButton from "../../components/textButton";
import Chip from "../../components/chip";
import Badge from "../../components/badge";
import CustomLogo from "../../components/logo";
import TextField from "../../components/textField";
import TabBar from "../../components/tabBar";
import Dropdown from "../../components/dropDown";
import TimePicker from "../../components/timePicker";
import CustomEventCard from "../../components/eventCard";
import {
  black,
  elevationDark1,
  elevationDark1Color,
  elevationDark2,
  elevationDark2Color,
  elevationDark3,
  elevationDark3Color,
  elevationDark4,
  elevationDark4Color,
  elevationDark5,
  elevationDark5Color,
  elevationLight1,
  elevationLight2,
  elevationLight3,
  elevationLight4,
  elevationLight5,
  white,
} from "../../lib/rebrandColors/colors";
import CalendarCard from "../../components/calendarCard";
import OnBar from "../../components/onBar";
import MonthlyCalendar from "../../components/monthlyCalendar";
import CalendarContainer from "../../components/calendarContainer";
import { fetchOrgEvents } from "../../functions/events";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function TestScreen() {
  const [user, waiting] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const orgID = localStorage.getItem("orgID");
  const [isOverviewAccount, setIsOverviewAccount] = useState(
    localStorage.getItem("isOverviewAccount") === "true" || false
  );
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID") || ""
  );
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const [rate, setRate] = useState(localStorage.getItem("rate") || 0.16);
  const [time, setTime] = useState("");

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = await fetchOrgEvents(orgID, setEvents);
      setEvents(eventsData);
    };

    if (orgID) {
      fetchEvents();
    }
  }, [orgID]);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (waiting) return;
    if (!user) navigate("/login");
  }, [user, waiting, navigate]);

  const getUsers = async () => {
    setLoading(true);
    let usersData;
    if (selectedOrgID !== "") {
      usersData = await fetchUsersFromOrganization(selectedOrgID);
    } else {
      usersData = await fetchUsersFromOrganization(orgID);
    }
    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    if (orgID) {
      getUsers();
    }
  }, [orgID, selectedOrgID]);
  const [activeI, setActiveI] = useState(0);
  const labelArr = ["one", "two", "three", "four"];
  const pressOne = () => {
    setActiveI(0);
    alert("one was clicked!");
  };
  const pressTwo = () => {
    setActiveI(1);
    alert("two was clicked!");
  };
  const pressThree = () => {
    setActiveI(2);
    alert("three was clicked!");
  };
  const pressFour = () => {
    setActiveI(3);
    alert("four was clicked!");
  };
  const pressArr = [pressOne, pressTwo, pressThree, pressFour];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropValue, setDropValue] = useState("Select");
  const dropdownClick = (item) => {
    alert(item);
    setDropValue(item);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "89vh",
        backgroundColor: "#F2F4F8",
        marginTop: "68px",
      }}
    >
      {/* <Header
        selectedOrgID={selectedOrgID}
        setSelectedOrgID={setSelectedOrgID}
        setTimezone={setTimezone}
        setRate={setRate}
        width={windowDimensions.width}
        isOverviewAccount={isOverviewAccount}
      /> */}
      {/* <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <CalendarContainer
          events={events}
          // {[
          //   {
          //     date: "2025-01-04",
          //     title: "Presidio Gym",
          //     subtitle: "Firmware update",
          //     iconName: "building",
          //   },
          //   {
          //     date: "2024-10-23",
          //     title: "Holiday",
          //     subtitle: "",
          //     iconName: "holiday",
          //   },
          //   {
          //     date: "2025-1-10",
          //     title: "Holiday",
          //     subtitle: "",
          //     iconName: "holiday",
          //   },
          // ]}
        ></CalendarContainer>
      </div>
      {/* <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <MonthlyCalendar
          month={9}
          year={2024}
          events={[
            {
              date: "2025-01-04",
              title: "Presidio Gym",
              subtitle: "Firmware update",
              iconName: "building",
            },
            {
              date: "2024-10-23",
              title: "Holiday",
              subtitle: "",
              iconName: "holiday",
            },
            {
              date: "2025-1-10",
              title: "Holiday",
              subtitle: "",
              iconName: "holiday",
            },
          ]}
        ></MonthlyCalendar>
      </div>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <MonthlyCalendar
          events={[
            {
              date: "2025-01-04",
              title: "Presidio Gym",
              subtitle: "Firmware update",
              iconName: "building",
            },
            {
              date: "2024-10-23",
              title: "Holiday",
              subtitle: "",
              iconName: "holiday",
            },
          ]}
        ></MonthlyCalendar>
      // </div> */} 
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <OnBar></OnBar>
      </div>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <CalendarCard
          dayNumber={30}
          currentDayNumber={30}
          events={[
            {
              title: "Presidio Gym",
              subtitle: "Firmware update",
              iconName: "building",
            },
            {
              title: "Meeting",
              subtitle: "Room 101",
              iconName: "arrow-left",
            },
            // {
            //   title: "Lunch",
            //   subtitle: "Cafeteria",
            //   iconName: "arrow-right",
            // },
          ]}
        />
      </div>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <CalendarCard
          dayNumber={28}
          currentDayNumber={20}
          state=""
        ></CalendarCard>
        <CalendarCard
          dayNumber={29}
          currentDayNumber={29}
          state=""
        ></CalendarCard>
        <CalendarCard
          dayNumber={30}
          currentDayNumber={0}
          state=""
          weekend={true}
        ></CalendarCard>
        <CalendarCard
          dayNumber={1}
          currentDayNumber={0}
          state="disabled"
          weekend={true}
        ></CalendarCard>
      </div>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <CustomEventCard
          eventTitle={"Presidio Gym"}
          eventSubtitle={"Fireware update"}
          iconName={"building"}
        ></CustomEventCard>
      </div>
      {/* icons */}
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <div style={{ backgroundColor: black }}>
          <CustomIcon />
        </div>
        <CustomIcon color={black} />
      </div>
      {/* time picker */}
      <div>
        {/* timespan will likely need to come from state variable */}
        <TimePicker timeSpan={"Month"} setTime={setTime} />
        <TimePicker timeSpan={"Week"} setTime={setTime} />
        <TimePicker timeSpan={"Day"} setTime={setTime} />
      </div>
      {/* elevation light */}
      <div
        style={{
          width: "500px",
          height: "100px",
          display: "flex",
          justifyContent: "space-evenly",
          backgroundColor: white,
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "75px",
            height: "75px",
            boxShadow: elevationLight1,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            boxShadow: elevationLight2,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            boxShadow: elevationLight3,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            boxShadow: elevationLight4,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            boxShadow: elevationLight5,
          }}
        ></div>
      </div>
      {/* elevation dark */}
      <div
        style={{
          width: "500px",
          height: "100px",
          display: "flex",
          justifyContent: "space-evenly",
          backgroundColor: black,
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: elevationDark1Color,
            boxShadow: elevationDark1,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: elevationDark2Color,
            boxShadow: elevationDark2,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: elevationDark3Color,
            boxShadow: elevationDark3,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: elevationDark4Color,
            boxShadow: elevationDark4,
          }}
        ></div>
        <div
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: elevationDark5Color,
            boxShadow: elevationDark5,
          }}
        ></div>
      </div>
      {/* buttons */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
        }}
      >
        <div
          style={{
            backgroundColor: white,
          }}
        >
          <CustomButton onClick={() => alert("Button 1 was clicked!")} />
          {/* <Badge label="2" /> */}
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <CustomButton
            filled={true}
            onClick={() => alert("Button 2 was clicked!")}
          />
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <CustomButton
            filled={true}
            colored={false}
            onClick={() => alert("Button 3 was clicked!")}
          />
        </div>
      </div>
      {/* text buttons */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
        }}
      >
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <TextButton onPress={() => alert("text 1 was clicked!")} />
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <TextButton
            underlined={true}
            onPress={() => alert("text 2 was clicked!")}
          />
        </div>
      </div>
      {/* icon buttons */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
        }}
      >
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <IconButton onClick={() => alert("icon 1 was clicked!")} />
        </div>
        <div
          style={{
            backgroundColor: white,
          }}
        >
          <IconButton
            tone={"light"}
            onClick={() => alert("icon 2 was clicked!")}
          />
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <IconButton
            buttonType={"filled"}
            colored={false}
            onClick={() => alert("icon 3 was clicked!")}
          />
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <IconButton
            buttonType={"standard"}
            colored={false}
            onClick={() => alert("icon 4 was clicked!")}
          />
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <IconButton
            buttonType={"outlined"}
            colored={false}
            onClick={() => alert("icon 5 was clicked!")}
          />
        </div>
      </div>
      {/* chips */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
        }}
      >
        <Chip />
        <div>
          <Chip alert={true} />
          <Badge label="2" />
        </div>
        <div>
          <Chip alert={true} />
          <Badge />
        </div>
      </div>
      {/* logos */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
          marginTop: "30px",
        }}
      >
        <div>
          <CustomLogo />
        </div>
        <div
          style={{
            backgroundColor: black,
          }}
        >
          <CustomLogo name="stacked-negative" />
        </div>
        <div
          style={{
            backgroundColor: black,
            width: "55px",
            height: "55px",
          }}
        >
          <CustomLogo name="symbol-negative" width="50px" height="50px" />
        </div>
      </div>
      {/* text field */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
          marginTop: "100px",
        }}
      >
        <div
          style={{
            backgroundColor: black,
            width: "350px",
          }}
        >
          <TextField />
          <TextField label="label" />
          <TextField label="label" descriptionText="description" />
        </div>
      </div>
      {/* tab bar */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '750px',
        height: '100px',
        marginTop: '100px',
      }}>
        <div style={{
          backgroundColor: black,
          width: '212px',
          height: '100px'
        }}>
          <TabBar labelArr={labelArr} onPressArr={pressArr} activeIndex={activeI} size="small" />
        </div>
      </div>
      {/* dropdown */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "750px",
          height: "100px",
        }}
      >
        <Dropdown
          list={labelArr}
          value={dropValue}
          onSelect={dropdownClick}
          setIsDropdownOpen={setIsDropdownOpen}
          isDropdownOpen={isDropdownOpen}
        />
      </div>
    </Box>
  );
}
