import React from 'react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import CalendarCard from '../calendarCard';
import { neutral400 } from '../../lib/rebrandColors/colors';

export default function MonthlyCalendar({ date = dayjs(), events }) {
  const month = dayjs(date).month(); // Extract month from date
  const year = dayjs(date).year(); // Extract year from date

  // Calculate the first day of the specified month and the total number of days
  const firstDayOfMonth = dayjs().year(year).month(month).date(1);
  const startDay = firstDayOfMonth.day(); // Day of the week the month starts on
  const daysInMonth = firstDayOfMonth.daysInMonth(); // Number of days in the specified month

  // Generate days for the calendar grid
  const days = [];

  // Add previous month's overflow days
  if (startDay > 0) {
    const prevMonthDays = dayjs(firstDayOfMonth).subtract(1, 'month').daysInMonth();
    for (let i = startDay - 1; i >= 0; i--) {
      days.push(
        <CalendarCard
          key={`prev-${i}`}
          dayNumber={prevMonthDays - i}
          state="disabled"
          weekend={((startDay - i - 1) % 7 === 0 || (startDay - i - 1) % 7 === 6)} // Check if the day is a weekend
          events={[]} // No events for disabled days
        />
      );
    };
  };

  // Add current month's days
  for (let day = 1; day <= daysInMonth; day++) {
    const date = dayjs().year(year).month(month).date(day);
    const isWeekend = date.day() === 0 || date.day() === 6; // Check if Saturday or Sunday

    const dateEvents = events.filter(
      (event) =>
        dayjs(event.date).date() === day &&
        dayjs(event.date).month() === month &&
        dayjs(event.date).year() === year
    );

    days.push(
      <CalendarCard
        key={day}
        dayNumber={day}
        weekend={isWeekend}
        currentDayNumber={dayjs().date() === day && month === dayjs().month() && year === dayjs().year() ? day : null}
        events={dateEvents}
      />
    );
  };

  // Add next month's overflow days to fill the grid to 35 cells (7x5)
  const nextMonthDays = 35 - days.length; // A 7x5 grid (35 days)
  for (let i = 1; i <= nextMonthDays; i++) {
    days.push(
      <CalendarCard
        key={`next-${i}`}
        dayNumber={i}
        state="disabled"
        weekend={(days.length + i - 1) % 7 === 0 || (days.length + i - 1) % 7 === 6} // Check if the day is a weekend
        events={[]} // No events for disabled days
      />
    );
  };

  return (
    <Box>
      {/* Calendar Grid */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 150px)',
          justifyContent: 'center',
          rowGap: '0px',
        }}
      >
        {/* Days of the Week Headers */}
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <Typography key={day} variant="subtitle1" sx={{ textAlign: 'center', color: neutral400 }}>
            {day}
          </Typography>
        ))}

        {/* Calendar Days */}
        {days}
      </Box>
    </Box>
  );
}
